@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.zoom-in-out {
  animation: zoom 2s infinite; /* Adjust duration and timing as needed */
}
